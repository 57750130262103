import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { useSelector, useDispatch, connect } from "react-redux";

import { Icon } from "@iconify/react";
import { getAllStores } from "../../Redux/Actions";
import { BASE_URL } from "../../ApiManager/client-config";
import i18next from "i18next";
const lang = localStorage.getItem("lang") || "en";

class OurStores extends Component<{}, {}> {
  constructor(readonly props: any) {
    super(props);
  }

  componentDidMount(): void {
    this.props.getStores();
  }

  // NextArrow = (props: any) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className="nex-arrow-slider-halla"
  //       style={{ ...style, fontSize: "84px", color: "#fff" }}
  //       onClick={onClick}
  //     >
  //       <Icon icon="iconamoon:arrow-right-2-light" />
  //     </div>
  //   );
  // };

  // PrevArrow = (props: any) => {
  //   const { style, onClick } = props;
  //   return (
  //     <div
  //       className="previous-arrow-slider-halla"
  //       style={{ ...style, fontSize: "84px", color: "#fff" }}
  //       onClick={onClick}
  //     >
  //       <Icon icon="iconamoon:arrow-left-2-light" />
  //     </div>
  //   );
  // };

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // nextArrow: this.NextArrow,
    // prevArrow: this.PrevArrow,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
          // arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  render() {
    return (
      // =====

      <div className="halla-store-slider-images container">
        {this.props.storesForLanding.length === 0 ? (
          <span></span>
        ) : (
          <h4>
            {i18next.t("landingPage.store_tag_line")}
          </h4>
        )}
        <div className="web-slider-add">
          {this.props.storesForLanding.length &&
          this.props.storesForLanding.length > 3 ? (
            <Slider {...this.settings}>
              {this.props.storesForLanding.map((store: any) => {
                return (
                  <div className="halla-stores-images px-4">
                    <img
                      className="halla-store__img"
                      src={`${BASE_URL}${store.store_image}`}
                    />
                    <h3>
                      {lang == 'en' ? store.store_name : store.store_name_ar}
                    </h3>
                    <a
                      href={`https://www.google.com/maps?q=${store.lat},${store.lng}`}
                      target="_blank"
                    >
                      {i18next.t("landingPage.get_direction")}
                    </a>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="row justify-content-center">
              {this.props.storesForLanding.map((store: any) => {
                return (
                  <div className="col-lg-4 halla-stores-images px-4">
                    <img
                      className="halla-store__img"
                      src={`${BASE_URL}${store.store_image}`}
                    />
                    <h3>{store.store_name}</h3>
                    <a
                      href={`https://www.google.com/maps?q=${store.lat},${store.lng}`}
                      target="_blank"
                    >
                      {i18next.t("landingPage.get_direction")}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="mob-slider-add">
          <Slider {...this.settings}>
            {this.props.storesForLanding.map((store: any) => {
              return (
                <div className="halla-stores-images px-4">
                  <img
                    className="halla-store__img"
                    src={`${BASE_URL}${store.store_image}`}
                  />
                  <h3>{store.store_name}</h3>
                  <a
                    href={`https://www.google.com/maps?q=${store.lat},${store.lng}`}
                    target="_blank"
                  >
                    {i18next.t("landingPage.get_direction")}
                  </a>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      // ========
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    storesForLanding: state.login.storesForLanding,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getStores: function () {
      dispatch(getAllStores());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OurStores);
